*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // This defines what 1rem is
  font-size: 62.5%; //1rem = 10px; 10px/16px = 62.5%

  @include respond(tab-land) {
    // width < 1200?
    font-size: 56.25%; // 1 rem = 9px, 9/16 = 56.25%

    body #root .app_responsive {
      width: 155vw;
    }

    body #root .app_project_responsive {
      width: 159vw;
      height: 144vw;
    }

    body #root .appQuote_responsive {
      width: 142vw;
    }

    body #root .app .app_container_responsive {
      height: 120vw;
      width: 155vw;
      align-items: start !important;
    }

    body #root .app .app_container #projectTablePage #projectFilterBar .project_filter_responsive {
      width: 55% !important;
      flex-wrap: wrap;
      row-gap: 8px;
    }

    body #root .app .app_container #projectTablePage {
      width: 140vw;
    }

    body #root .app .app_container #pmTool .project_widgets_responsive {
      flex-direction: column;
    }

    body #root .app .app_container #pmTool .project_widgets_responsive .project_info_responsive {
      flex-direction: row;
      width: 100%;
      margin-bottom: 15px;
      column-gap: 10px;
    }

    body #root .app .quote_container_responsive {
      height: 98vw;
      align-items: start !important;
    }

    body #root .app .navbar_responsive {
      width: 155vw;
    }

    body #root .app .navbar_project_responsive {
      width: 159vw;
    }

    body #root .app .quoteNav_responsive {
      width: 142vw;
    }

    .quote_tags_responsive {
      flex-direction: column;
      row-gap: 7px;
    }
  }

  @include respond(tab-port) {
    // width < 900?
    font-size: 50%; // 1 rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 75%; // 1 rem = 12, 12/16
  }
}

body {
  box-sizing: border-box;
}

// Remove up down arrows from number input
// 
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}