.productsTable {
  & table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    // Column widths
    & .col {
      &-buttons {
        width: 2%;
      }
      &-sku {
        width: 7%;
      }
      &-name {
        width: 15%;
      }
      &-description {
        width: 18%;
      }
      &-variants{
        width: 10%; 
      }
      &-qty {
        width: 3%;
      }
      &-list_price,
      &-sale_price,
      &-cost,
      &-total_price,
      &-profit,
      &-margin,
      &-exclusion,
      &-tax {
        width: 5%;
      }
      &-list_price input {
        width: 85%;
      }
      &-sale_price input {
        width: 85%;
      }
    }

    & tr {
      
      & th {
        text-decoration: none;
      }

      .productsTable_deleteBtn {
        visibility: visible;
      }

      &.productsTable_row {
        border-bottom: 2px solid #E5E5E5
      }
    }

    & td {
      white-space: nowrap;
      padding: .5rem 1rem;

      &.left {
        text-align: left;
      }
      &.center {
        text-align: center;
      }
      &.right {
        text-align: right;
      }

      & textarea {
        width: 100%;
        height: 4rem;
        font-size: 1rem;
      }

      & input {
        width: 100%;
      }
      // When any user input is typed into td
      & .altered {
        color: blue;
      }
    }
  }
  &_qty {
    max-width: 4rem;
    text-align: center;
  }
}

.productsTable_deleteBtn {
  font-size: 2.2rem;
  text-align: right;
  visibility: hidden;
  color: red;
  font-weight: bold;
  cursor: pointer;
}

colVariantScroll {
    max-height: 10rem;
    overflow-x: hidden;
    overflow-y: scroll;
}

.wrap-text {
    display: block;
    width: 100%;
    white-space: normal;
    text-align: center;
}
