.findProducts {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.findProducts_searchBar {
  display: flex;
  margin-bottom: 1rem;
  & :first-child {
    margin-right: 3rem;
  }
  & input[type='text'] {
    min-width: 30rem;
    flex-grow: 3;
  }
}

.findProducts_productResults {
  height: 360px;
  border: 1px solid black;
  border-radius: 5px;
  overflow: auto;

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
  }

  & colgroup {
    // First child name
    & :nth-child(1) {
      width: 40%;
    }
  }

  & tr {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  & td {
    border-bottom: 1px solid black;
  }

  & tr:last-child td {
    border-bottom: none;
  }
}
