.toast {
  z-index: 101;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  font-size: 2rem;
}

.toast ul {
  list-style: none;
  display: flex;
  flex-direction: column;
}

.toast li {
    background-color: rgba($color: #f8f8f8, $alpha: 1);
    padding: 1rem;
    margin: 1rem 0;
    border: 1px solid #3e3e3e;
    border-radius: 5px;

    &.toast-success {
        color: $primary-color-blue;
    }

    &.toast-error {
        color: $primary-color-red;
    }

    &.toast-warning {
        color: $color-primary-lorax;
    }
}

.top-toast {
  z-index: 101;
  position: fixed;
  top: 2rem;
  left: 2rem;
  font-size: 2rem;
  background-color: rgba($color: #f8f8f8, $alpha: 1);
  padding: 1rem;
  margin: 1rem 0;
  border: 1px solid #3e3e3e;
  border-radius: 5px;
  color: $primary-color-red;
}