.quotesTable {
    & table {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
    }

    &_row {
      border-bottom: 2px solid #E5E5E5
    }

    & th {
        padding: 2.7rem 1rem 5px 4rem;
        border-bottom: 4px solid #FE7C26;
    }

    & td {
        padding-right: 1rem;
        padding-left: 4rem;
    }
}

// Column widths
.quotesTable {
  &_quoteNum {
    width: 15%;
  }
  &_name {
    width: 9%;
  }
  &_tagName {
    width: 12%;
  }
  &_date {
    width: 8%;
  }
  &_company {
    width: 12%;
  }
  &_price {
    width: 9%;
  }
  &_preparedBy {
    width: 11%;
  }
  &_salesPerson {
    width: 10%;
  }
  &_editQuote {
    width: 14%;
  }
}
