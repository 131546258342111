.cutSheets{
  position: relative;

  & h3{
    margin-bottom: 3rem;
  }
  // Search label
  & label {
  display: inline-block;
  margin-right: 2rem;
  margin-bottom: 2rem;
  }

}

// Headline grid
.cutSheets_headline_grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;

}

// Main grid
.cutSheets_grid{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2 rem;
}

.cutSheets ul {
  list-style-type:none;
}

// Buttons

.cutSheets {
  & button {
    margin-bottom: 1rem;
  }

  &_headline_buttons{
    display: flex;
    gap: 3rem;
  }
  

  &_addBtn, &_removeBtn{
    margin-right: 1.5rem;
  }
  &_viewBtn{
    margin-right: 2rem;
  }
}

// sheetsToAdd component
.cutSheets_sheetsToAdd {
  // P explaining to drag sheets to reorder
  & p:first-of-type {
    margin-bottom: 2rem;
  }
  
  &_listNumber {
    font-weight: bold;
    display: inline-block;
    min-width: 2rem;
  }
}

// PDF being built - in progress div

.cutSheets_downloading{
  position: absolute;
  // width: 80rem;
  top: 20rem;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 2;

  display: flex;
  align-items: center;
  gap: 2rem;

  padding: 3rem;
  background-color: $app-bg-color;
  border: 2px solid $primary-color-blue;
  border-radius: 5px;
  
  & h3 {
    text-align: center;
    margin: 0;
    display: inline-block;
  }

  // Loading gif
  & img {
    width: 3rem;
  }
}