.sendEmail_form {
  // max-width: 60rem;
}

$spacing-gap: .5rem;

.sendEmail_form {
  & textarea {
    margin-top: $spacing-gap;
    display: block;
    width: 100%;
    // min-height: 20rem;

    &, #email-body {
      height: 15rem
    }
  }

  & button {
    margin: auto;
    margin-top: 2rem;
  }
}

.sendEmail_form_grid {
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 1rem;

  & input {
    // min-width: 35rem;
    width: 100%;
  }

  &_note {
    font-size: 1.4rem;
    font-style: italic;
    margin-bottom: $spacing-gap;
  }
}

// email body text input
#email-body {
  display: block;
  width: 100%;
  margin-bottom: $spacing-gap;
}

// SalesPerson css
.sendEmail_salesPerson {
  margin: 3rem 0;

  &_grid {
    margin-left: .5rem;
    @extend .sendEmail_form_grid;
    grid-template-columns: 13rem auto;
  }
}