

.width-100, .w-100 {
  width: 100%;
}

.width-90, .w-90 {
  width: 90%;
}

.width-85, .w-85 {
  width: 85%;
}

.width-80, .w-80 {
  width: 80%
}

.width-75, .w-75 {
  width: 75%
}

.width-70, .w-70 {
  width: 70%
}

.width-65, .w-65 {
  width: 65%
}

.width-60, .w-60 {
  width: 60%
}

.width-55, .w-55 {
  width: 55%
}

.width-50, .w-50 {
  width: 50%
}

.width-45, .w-45 {
  width: 45%
}

.width-40, .w-40 {
  width: 40%
}

.width-35, .w-35 {
  width: 35%
}

.width-30, .w-30 {
  width: 30%
}

.width-25, .w-25 {
  width: 25%
}

.width-20, .w-20 {
  width: 20%
}

.width-15, .w-15 {
  width: 15%
}

.width-10, .w-10 {
  width: 10%
}

.width-5, .w-5 {
  width: 5%
}

.width-1, .w-1 {
  width: 1%
}

.max-width-25px, .max-w-25px {
  max-width: 25px;
}

.max-width-50px, .max-w-50px {
  max-width: 50px;
}

.max-width-100px, .max-w-100px {
  max-width: 100px;
}

.max-width-200px {
  max-width: 200px;
}


.max-width-300px {
  max-width: 300px;
}

.max-width-350px {
  max-width: 350px;
}

.max-width-450px {
  max-width: 450px;
}

.max-width-500px {
  max-width: 500px;
}

.max-width-550px {
  max-width: 550px;
}

.min-width-85px {
  min-width: 85px;
}

.min-width-150px {
  min-width: 150px;
}

.min-width-215px {
  min-width: 215px;
}

.min-width-300px {
  min-width: 300px;
}

.min-width-325px {
  min-width: 325px;
}

.min-width-350px {
  min-width: 350px;
}

.min-width-400px {
  min-width: 400px;
}


.width-150px {
  width: 150px;
}

.width-175px {
  width: 175px;
}

.width-300px {
  width: 300px;
}

.width-350px {
  width: 350px;
}

.width-400px {
  width: 400px;
}

.width-450px {
  width: 450px;
}

.width-500px {
  width: 500px;
}

.width-600px {
  width: 600px;
}

.width-700px {
  width: 700px;
}

.width-800px {
  width: 800px;
}

.width-1500px {
  width: 1500px;
}

.width-100vw {
  width: 100vw;
}

.width-75vw, .w-75vw {
  width: 75vw;
}

.min-width-450px {
  min-width: 450px;
}

.min-width-500px {
  min-width: 500px;
}

.icon-width {
  width: 2rem
}

.width-fit-content {
  width: fit-content;
}

.height-fit-content {
  height: fit-content;
}

.height-100, .h-100 {
  height: 100%;
}

.height-85, .h-85 {
  height: 85%;
}

.height-75, .h-75 {
  height: 75%;
}

.height-70, .h-70 {
  height: 70%;
}

.height-65, .h-65 {
  height: 65%;
}

.height-50, .h-50 {
  height: 50%;
}

.height-40, .h-40 {
  height: 40%;
}

.height-35, .h-35 {
  height: 35%;
}

.height-30, .h-30 {
  height: 30%;
}

.height-25, .h-25 {
  height: 25%;
}

.height-20, .h-20 {
  height: 20%;
}

.height-15, .h-15 {
  height: 15%;
}

.height-10, .h-10 {
  height: 10%;
}

.height-5, .h-5 {
  height: 5%;
}

.height-1, .h-1 {
  height: 1%;
}

.height-25px {
  height: 25px;
}

.height-40px {
  height: 40px;
}

.height-50px {
  height: 50px;
}

.height-200px {
  max-height: 200px;
}

.height-210px {
  max-height: 210px;
}

.height-225px {
  max-height: 225px;
}

.height-250px {
  max-height: 250px;
}

.height-300px {
  max-height: 300px;
}

.height-325px {
  height: 325px;
}

.height-350px {
  height: 350px;
}

.max-height-600px {
  max-height: 600px;
}

.height-100vh {
  height: 100vh;
}

.height-85vh {
  height: 85vh;
}

.height-70vh {
  height: 70vh;
}

.height-50vh {
  height: 50vh;
}

.min-height-265px {
  min-height: 265px;
}

.no-resize {
  resize: none;
}

.flex {
  display: flex;
}

.flex-column, .flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-flow-wrap {
  flex-flow: row wrap;
}

.word-wrap {
  word-wrap: break-word;
}

.justify-even {
  justify-content: space-evenly;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.height-200px {
  max-height: 200px;
}

.height-250px {
  max-height: 250px;
}

.border-collapse {
  border-collapse: collapse;
}

.no-wrap {
  white-space: nowrap;
}

.layout-fixed {
  table-layout: fixed;
}

.td-60 {
  width: 60px;
}

.td-100 {
  width: 100px;
}

.td-110 {
  min-width: 110px;
  max-width: 110px;
}

.td-130 {
  min-width: 130px;
  max-width: 130px;
}

.td-150 {
  min-width: 150px;
  max-width: 150px;
}

.wrap {
  word-wrap: break-word;
  white-space: pre;
}

.overflow-wrap {
  overflow-wrap: break-word;
}

.border-left-gray {
  border-left: 1px solid #707070;
}

.border-radius-btm-none {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.border-radius-top-none {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.box-shadow {
  box-shadow: 0px 2px 2px rgb(207, 198, 198);
}

// background colors here
.bg-orange {
  background-color: #FE7C26;
}

.bg-white {
  background-color: white;
}

.bg-blue {
  background-color: #3497D4;
}

.bg-light-blue {
  background-color: #70c7fd;
}

.blue-button:hover {
  background-color: #2487C4;
  // border: 2px solid #2487C4;
}

.small-blue-button:hover {
  background-color: #2487C4;
}

.bg-green {
  background-color: $color-primary-green;
}

.bg-green2 {
  background-color: #119a07;
}

.bg-light-green {
  background-color: #33b529;
}

.bg-red {
  background-color: $primary-color-red;
}

.bg-light-red {
  background-color: #E84040;
}

.bg-yellow {
  background-color: $color-secondary-marigold;
}

.bg-light-gray {
  background-color: #f1f1f1;
}

.bg-gray {
  background-color: #F8F8f8;
}

.bg-dark-gray {
  background-color: #7c7c7c;
}

// text colors here
.text-blue {
  color: #3497D4;
}

.text-orange {
  color: #FE7C26;
}

.text-lightblue {
  color: #70c7fd;
}

.text-white {
  color: white;
}

.text-red {
  color: #BF0000;
}

.text-red-hover:hover {
  color: #BF0000;
}

.text-black,
.black {
  color: black;
}

.text-orange {
  color: #FE7C26;
}

.green {
  border-color: $primary-color-green;
}

.gray-border {
  border-color: lightgray
}

.text-green {
  color: #119a07;
}

.text-light-gray {
  color: #565656
}

.text-gray {
  color: #00000029
}

.text-med-gray {
  color: #707070
}

// font sizes
.text-xxs {
  font-size: xx-small;
}

.text-xs {
  font-size: x-small;
}

.text-smaller {
  font-size: smaller;
}

.text-small {
  font-size: small;
}

.text-smedium {
  font-size: 1.3rem;
}

.text-med {
  font-size: 1.5rem;
}

.text-lrg {
  font-size: large;
}

.text-xl {
  font-size: larger;
}

.text-xxl {
  font-size: xx-large;
}

.text-xxxl {
  font-size: 4.5rem;
}

.text-profit-margin {
  font-size: 30px;
}

.newline-after::after {
  content: "\a";
  white-space: pre;
}

.lh-normal,
.line-height-normal {
  line-height: normal;
}

.line-height-xs {
  line-height: 8px;
}

.line-height-sm {
  line-height: 15px;
}

.line-height-120 {
  line-height: 120%;
}

.line-height-140 {
  line-height: 140%;
}

.line-height-150 {
  line-height: 150%;
}

// font styles here
.no-underline {
  text-decoration: none;
}

.underline {
  text-decoration: underline;
}

.no-bottom-border {
  border-bottom: none;
}

.list-none {
  list-style: none;
}

.text-normal {
  font-weight: normal;
}

.text-bold,
.bold {
  font-weight: bold;
}

.text-bolder,
.bolder {
  font-weight: 750;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.horizontal-middle {
  margin-left: auto;
  margin-right: auto;
}

.vertical-middle {
  margin-top: auto;
  margin-bottom: auto;
}

.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-top {
  vertical-align: top
}

.text-end {
  text-align: end;
}

.text-start {
  text-align: start;
}

.text-left {
  text-align: left;
}

.table {
  display: table;
}

.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
}

// project issue specific
.project-box {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 6px #00000029;
  border-radius: 13px;
  opacity: 1;
  padding: 10px;
}

.leftIssueCol {
  overflow-y: scroll;
  max-width: 600px;
  min-width: 600px;
}

.rightIssuesCol {
  width: fit-content;
}

::-webkit-scrollbar {
  width: 16px;
  height: 10px;
  left: 100px;
  margin-left: 50px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 8px;
  width: 16px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #FE7C26;
}

.overflow-scroll {
  overflow-y: auto;
  overflow-x: hidden;
}

.overflow-horizontal-scroll {
  overflow-x: auto;
}

.overflow-vertical-scroll {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.issue-details {
  white-space: pre-line;
  word-wrap: break-word;
  // display: table-cell;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  max-height: 150px;
}

.issue-details-box {
  display: table-cell;
  max-height: 150px;
}

.activity-details {
  white-space: pre-line;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.webkit-display {
  display: -webkit-box;
}

.webkit-box-properties {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.max-h-35px {
  max-height: 35px;
}

.issue_row {
  max-height: 20px;
}

// access log specific
.access-table {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 6px #00000029;
  border-radius: 13px;
  opacity: 1;
}

.component-card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 6px #00000029;
  border-radius: 13px;
  opacity: 1;
}

.box-shadow {
  box-shadow: 0px 5px 6px #00000029;
}

// NavBar specific
.first-link-nav {
  margin-left: 150px;
}

// svg formatting
.powerOff {
  height: 20px;
}

.top-border {
  border-top: 1px solid black;
}

.border-radius-xs {
  border-radius: 5px;
}

.border-radius-sm {
  border-radius: 8px;
}

.border-radius-md {
  border-radius: 12px;
}

.border-thin {
  border: 1px solid black;
}

.serch-projects-width {
  width: 415px;
}

.icon-rtl {
  background: url("../../assets/search.svg") no-repeat 97.5%;
  background-size: 15px;
}

.icon-rtl2 {
  background: url("../../assets/search.svg") no-repeat 97.5%;
  background-size: 22px;
}

.icon-rtl-md {
  background: url("../../assets/search.svg") no-repeat 97.5%;
  background-size: 20px;
}

.height-1em {
  height: 1em;
}

.height-1dot3em {
  height: 1.3em;
}

.height-1dot5em {
  height: 1.5em;
}

.hidden {
  visibility: hidden;
}

.letter-spacing-1px {
  letter-spacing: 1px;
}

.project-dropdowns>select:disabled {
  opacity: 1;
  color: black;
  border: 1px solid gray;
  background: white;
  pointer-events: none;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark img {
  margin-bottom: 5px;
  margin-top: -1px;
  width: 16px;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: white;
  background-color: white;
  border: 1px solid gray;
  border-radius: 4px;
  margin-top: 3px;
}

/* On mouse-over, add a grey background color */
// .checkbox-container:hover input ~ .checkmark {
//   background-color: #ccc;
// }

// .checkbox-container input:checked ~ .checkmark {
//   background-color: #2196F3;
// }


.checkbox-container {
  vertical-align: middle;
}

.checkbox-container input:not(:checked)~.checkmark {
  background-color: white;
  border: 1px solid gray;
  border-radius: 4px;
}


/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkmark:after {
  display: block;
}

.hide {
  display: none;
}

.inline-block {
  display: inline-block;
}

.display-inline {
  display: inline;
}

.position-fixed {
  position: fixed;
}

.position-sticky-top {
  position: sticky;
  top: 0%;
  z-index: 10;
}

.position-sticky {
  position: sticky;
  top: 0
}

.sticky-underline-orange {
  box-shadow: inset 0 -3px 0 #FE7C26;
}

.position-absolute {
  position: absolute;
}

.pointer {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}

/* Wrapper styles */
.wysiwyg-wrapper-class {
  background-color: #f5f5f5; /* Light gray background for the wrapper */
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Editor styles */
.wysiwyg-editor-class {
  background-color: #ffffff; /* White background for the editor */
  padding: 15px;
  max-height: 300px; /* Limit the height of the editor */
  overflow-y: auto; /* Add vertical scroll if content exceeds height */
  border-radius: 5px;
  border: 1px solid #ddd;
  color: #333; /* Text color */
}

.trash-icon {
  height: 15px;
}

// form details
.form-label {
  display: block;
  margin-bottom: 5px;
  font-size: small;
  font-weight: 650;
}

.form-input {
  border-radius: 4px;
  border: 1px solid #565656;
  padding: 2px;
}

.text-input-sm {
  height: 20px;
}

.text-input-large {
  height: 80px;
  width: 100%;
  max-width: 420px;

}

.text-input-xl {
  height: 200px;
  width: 100%;
}

.max-width-800px {
  max-width: 800px;
}

//checkboxes
.sm-box {
  width: 17px;
  height: 17px;
}

.sm-box:checked {
  content: url(../../assets/checkmark.svg);
  appearance: none;
  border: 1px solid gray;
  border-radius: 4px;
}

#checkboxes:hover {
  border-color: #119A07;
}

.cancel-box {
  width: 17px;
  height: 17px;
}

.cancel-box:checked {
  content: url(../../assets/xmark.svg);
  appearance: none;
  border: 1px solid gray;
  border-radius: 4px;
}

#cancel-checkbox:hover {
  border-color: #f70000
}

// image modal
.modal {
  position: fixed;
  left: 30%;
  top: 20%;
  right: 30%;
  bottom: 20%;
  background: #FFFFFF 0% 0%;
  box-shadow: 0px 5px 6px #00000029;
  display: flex;
  // align-items: center;
  // justify-content: center;
  transform: none;
}

.email-modal {
  position: fixed;
  left: 25%;
  top: 15%;
  right: 25%;
  bottom: 15%;
  background: #FFFFFF 0% 0%;
  box-shadow: 0px 5px 6px #00000029;
  display: flex;
  transform: none;
  z-index: 10;
}

.po-modal {
  position: fixed;
  left: 39%;
  top: 35%;
  right: 39%;
  bottom: 35%;
  background: #FFFFFF 0% 0%;
  box-shadow: 0px 5px 6px #00000029;
  display: flex;
  transform: none;
  z-index: 10;
}

.invoice-modal {
  position: fixed;
  min-height: 350px;
  left: 39%;
  top: 35%;
  right: 39%;
  bottom: 40%;
  background: #FFFFFF 0% 0%;
  box-shadow: 0px 5px 6px #00000029;
  display: flex;
  transform: none;
  z-index: 10;
}

.conflict-modal {
  position: fixed;
  min-height: 450px;
  left: 10%;
  top: 25%;
  right: 10%;
  bottom: 40%;
  background: #FFFFFF 0% 0%;
  box-shadow: 0px 5px 6px #00000029;
  display: flex;
  transform: none;
  z-index: 10;
}

.error-modal {
  position: fixed;
  left: 33%;
  top: 28%;
  right: 33%;
  bottom: 28%;
  background: #FFFFFF 0% 0%;
  box-shadow: 0px 5px 6px #00000029;
  display: flex;
  transform: none;
  z-index: 10;
}

.prompt-modal {
  position: fixed;
  left: 35%;
  top: 30%;
  right: 35%;
  bottom: 30%;
  background: #FFFFFF 0% 0%;
  box-shadow: 0px 5px 6px #00000029;
  display: flex;
  transform: none;
  min-height: 350px;
}

.full-orange-background {
  background: #FE7C26;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.modal-logo {
  position: absolute;
  top: 2%;
  left: 2%;
  height: 15%;
}

.close-button {
  z-index: 10;
  position: absolute;
  top: 0.5%;
  right: 1%;
}

.resize-image {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.loading-table {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.bottom-right {
  bottom: 6%;
  right: 6%;
}

.thumbnail {
  width: 30px;
  height: 30px;
}

#page-mask {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 75vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  text-align: center;
}

.green-button:hover {
  background-color: #119a07;
}

.light-green-border {
  border: 2px solid #33b529;
}

.light-green-border:hover {
  border: 2px solid #119a07;
}

.light-red-border {
  border: 1px solid #E84040;
}

.light-red-border:hover {
  border: 1px solid #D80000;
}

.delete-button:hover {
  background-color: #D80000;
}

.b-none {
  border: none;
}

.b1-black,
.b1 {
  border: 1px solid black;
}

.b1-white {
  border: 1px solid white;
}

.b1-red {
  border: 1px solid red;
}

.b2-red {
  border: 2px solid red;
}

.b2-blue {
  border: 2px solid #3497D4;
}

.b2-green {
  border: 2px solid #119a07;
}

.b2-orange {
  border: 2px solid #FE7C26;
}

.border-green {
  border: 1px solid #119A07;
}

.border-orange {
  border: solid #FFC337 !important;
}

.underline-orange {
  border-bottom: 3px solid #FE7C26;
}

.underline-light-gray {
  border-bottom: 2px solid #E5E5E5
}

.underline-black {
  border-bottom: 1px solid black;
}

.z-index-1 {
  z-index: 1;
}

.sync-button-location {
  bottom: 10%;
  right: 7%;
}

.img-black {
  filter: brightness(0%);
}

.grayscale {
  filter: grayscale(100%);
}

.no-visited:visited,
.no-visited {
  color: black;
}

.no-visited:hover {
  color: $primary-color-blue;
}

.disabled-btn {
  color: $color-disabled;
  border: 3px solid $color-disabled;
}

.size-20px {
  width: 20px;
  height: 20px;
}

.size-25px {
  width: 25px;
  height: 25px;
}

.size-27px {
  width: 27px;
  height: 27px;
}

.size-30px {
  width: 30px;
  height: 30px;
}

.size-40px {
  width: 40px;
  height: 40px;
}

.width-45 {
  width: 45%
}

.size-50px {
  width: 50px;
  height: 50px;
}

.invisible {
  visibility: hidden;
}

.filter-btn:hover {
  color: $color-primary-orange;
}

.darken-background:hover {
  background-color: #E0E0E0
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: help;
}

.tooltip-icon {
  display: inline-block;
  margin-left: 5px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background-image: url(../../assets/info_icon.png);
  background-repeat: no-repeat;
  background-size: 20px;
  border-radius: 50%;
  font-size: 14px;
  font-weight: bold;
  cursor: help;
  outline: none;
}

.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #FE7C26;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 10px;
}

.text-left {
  text-align: left;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 105%;
  border-width: 5px;
  border-style: solid;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.modal-tooltip {
  top: 160%;
  left: 50%;
  margin-left: -60px;
}

.modal-tooltiptext::after {
  left: 50%;
  margin-left: -5px;
  border-color: #FE7C26 transparent transparent transparent;
}

.product-tooltip {
  height: 85px;
  text-wrap: wrap;
  top: 50%;
  left: calc(100% + 5px);
  margin-left: 8px;
  transform: translateY(-50%);
}

.product-tooltiptext::after {
  top: 50%;
  left: -14px;
  margin-left: 0px;
  margin-top: -4px;
  border-color: transparent transparent transparent #FE7C26;
}

.double-orange-border {
  border: 2px solid #FE7C26;
  box-shadow:
    0 0 0 4px #ffffff,
    0 0 0 6px #FE7C26;
}

.sync-status-container {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin: 10px 0;
  cursor: default;
}

.sync-status-clickable {
  cursor: pointer;
}

.sync-status-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sync-status-failed {
  color: red;
}

.sync-status-success {
  color: green;
}

.sync-status-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.sync-status-arrow-icon {
  transform: rotate(0deg);
  transition: transform 0.3s;
}

.sync-reverse-arrow-direction {
  transform: rotate(180deg); /* Rotates the arrow when expanded */
}

.sync-status-details {
  margin-top: 10px;
  padding-left: 24px;
  border-left: 2px solid #ccc;
}

.sync-status-detail-item {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.sync-status-detail-item img {
  margin-right: 8px;
  width: 14px;
  height: 14px;
}

.shade-odd-rows {
  & table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
  }

  & th {
    padding: 1rem 1rem 1rem 1rem;
    text-align: left;
  }

  & tbody {
    border-bottom: 2px solid $color-grey-light-5;
  }

  & tr:nth-child(odd) {
    background-color: $color-grey-light-5;
  }

  & tr:nth-child(1) {
    background-color: $app-bg-color;
  }

  & td {
    padding: 1rem 1rem 1rem 1rem;
  }
}

.fixed-width-project-owner {
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fixed-width-installer {
  width: 122px;
  overflow: hidden;
  text-overflow: ellipsis;

}

.option-any-length {
  white-space: nowrap;
  overflow: hidden;
}

.previewDiv {
  position: fixed;
  /* Fixed position */
  // top: 35%; /* Center vertically */
  margin-bottom: 100px;
  // bottom: 100%;
  left: 50%;
  /* Center horizontally */
  transform: translate(-50%, -64%);
  /* Adjust to exact center */
  z-index: 1000;
  /* Ensure it's above other elements */
  width: 98vw;
  /* or any size you prefer */
  height: 95vh;
  border: 1px solid black;
  overflow: hidden;
  position: relative;
  max-height: 90vh;
}

.previewIframe {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent */
  z-index: 900;
  /* Below the previewDiv but above other elements */
}

.project-widgets {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  // justify-content: space-between;
}

.right-column {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  /* Adjust gap between components */
  width: calc(100% - 500px);
  /* Adjust width as per requirement */
}

.gap-3 {
  gap: 15px;
}

// .right-column > div {
//   flex: 1 1 400px; /* Adjust minimum width as per requirement */
// }

// .right-column > .large-widget {
//   flex: 1 1 400px; /* Adjust minimum width as per requirement */
// }

.config-container .config-items {
  display: flex;
  flex-direction: row;
  gap: 20px;
  /* Space between items */
  height: 100%;
  /* Adjust height as per requirement */
  flex-wrap: wrap;
  justify-content: center;
}

.widget-width {
  width: 45%;
}

.thin-widget-width {
  width: 25%;
}

.select-pointer div {
  cursor: pointer;
}

#sentry-feedback {
  z-index: 0 !important;
}

.dashboardNavbarContainer {
  .dashboardNavbar {
    background: $color-grey-light-2; // Light background for contrast and clarity
    padding: 1rem;
    border-radius: 20px; // Enhanced rounded corners for a modern appearance
    box-shadow: 0 4px 8px rgba($color-grey-dark-3, 0.15); // Soft shadow for depth
    display: flex;
    justify-content: center;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      gap: 1rem; // Space between items for a clean layout

      li {
        transition: transform 0.2s ease-in-out; // Smooth transition for hover effect

        &:hover {
          transform: translateY(-2px); // Slight elevation on hover for interactivity
        }
      }
    }

    .navLink {
      text-decoration: none;
      color: $color-grey-dark-5; // Readable and contrasted text color
      background-color: $color-grey-light-3; // Subtle background for each link
      padding: 0.5rem 1.5rem;
      border-radius: 15px; // Consistent rounded edges for all links
      transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;

      &:hover, &:focus {
        background-color: $color-primary-calypso; // Highlight color for hover/focus
        color: $color-grey-light-1; // Light color text for readability
        box-shadow: 0 2px 4px rgba($color-grey-dark-4, 0.2); // Subtle shadow for depth
      }
    }

    .active {
      font-weight: bold; // Bolder text for active link emphasis
      background-color: transparent; // Clear background for a focused underline effect

      &:after {
        content: '';
        display: block;
        margin: auto;
        height: 2px; // Thinner underline for a refined look
        width: 90%; // Underline width relative to the link width for balance
        background-color: $color-primary-green; // Underline color for distinction
        border-radius: 1px; // Slightly rounded edges on the underline for smoothness
        transition: background-color 0.3s; // Smooth transition for the underline color
      }
    }
  }
}

.cell-zero {
  background-color: #333; /* Dark background for zero values */
  color: #fff;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.3); /* Subtle box shadow */
}

.cell-negative {
  background-color: #ff7b7b; /* Background color for negative values */
  color: #fff;
  box-shadow: inset 0px 0px 10px rgba(255, 0, 0, 0.3); /* Subtle box shadow */
}

.cell {
  transition: background-color 0.3s; /* Smooth transition for background color */
}

// .cell:hover {
//   background-color: #f0f0f0; /* Light background on hover */
//   color: black;
// }

/* Styles specifically for the table within the GenericTable component */
.GenericTable {
  border-radius: 8px;
  overflow: hidden;
  background-color: #1e1e1e; /* Outer rim color */
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.29); /* Subtle box shadow */
}

.GenericTable th {
  padding: 12px;
  text-align: center;
  background-color: #222; /* Inner background color for header */
  color: #fff;
}

.GenericTable td {
  padding: 12px;
  text-align: center;
  color: #fff;
}

.GenericTable tr:nth-child(even) td {
  background-color: #222; /* Alternate row background color */
}

.table-scroll {
  overflow-y: scroll;
  display: block;
}

.table-scroll thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f8f9fa;
}

.btn-save {
  background-color: #119a07;
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
}

.btn-save:hover {
  background-color: #0d7e05;
}

.btn-cancel {
  background-color: #D80000;
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
}

.btn-cancel:hover {
  background-color: #BF0000;
}

.btn-delete {
  background-color: #D80000;
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
}

.btn-delete:hover {
  background-color: #BF0000;
}

.btn-edit {
  background-color: #3497D4;
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
}

.btn-edit:hover {
  background-color: #2487C4;
}

.sidebar {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #f0f0f0;
  border-radius: 50px;
  padding: 10px 5px;
  transition: width 0.3s ease;
  width: 40px; /* Control the width of the sidebar */
  overflow: hidden;
  z-index: 1000; /* Ensure it's above other content */
}

.sidebar:hover {
  width: 200px; /* Expand sidebar width on hover */
}

.sidebar-item {
  cursor: pointer;
  margin: 10px 0;
}

.sidebar:hover .sidebar-item {
  display: flex;
  align-items: center;
}

.sidebar-dot {
  height: 15px;
  width: 15px;
  background-color: #555; /* Dot color */
  border-radius: 50%; /* Round dots */
  margin: 0 10px;
  transition: background-color 0.3s ease;
}

.sidebar-dot.active {
  background-color: orange; /* Active dot color */
}

.sidebar:hover .sidebar-label {
  display: block; /* Show label text on hover */
}

.sidebar-label {
  white-space: nowrap;
  opacity: 0; /* Hide label text initially */
  transition: opacity 0.3s ease;
  display: none;
}

.sidebar:hover .sidebar-label {
  opacity: 1; /* Show label text on hover */
}


@keyframes fadeInScale {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.checkmark-icon {
  color: green;
  animation: fadeInScale 0.3s ease-out;
  margin-left: 8px; /* Adjust spacing to fit your layout */
  display: inline-block;
  vertical-align: middle; /* Align with text if in a table cell */
}

.saved-cell {
  background-color: #eaffea; /* Light green background */
  color: #034503; /* Darker green text */
  transition: background-color 0.4s, color 0.4s;
}

.number-input {
  width: 70px;
}

.dashboard-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  padding: 20px 0;
}

.dashboard-card {
  flex: 1 1 auto;
  text-align: center;
  padding: 20px;
  margin: 10px;
  min-width: 180px;
  background-color: #f5f8fa; /* Subtle background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Deeper shadow for more depth */
  border-radius: 10px; /* Slightly larger radius for a softer edge */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions for interaction */
}

.dashboard-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Enhanced hover effect */
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  color: #33475b; /* Darker shade for contrast */
  margin-bottom: 5px;
  font-family: 'Poppins', sans-serif; /* Modern, clean font */
}

.card-number {
  font-size: 24px; /* Larger size for emphasis */
  font-weight: 600;
  color: #00a4bd; /* Vibrant color for standout numbers */
  font-family: 'Poppins', sans-serif;
}

/* Nested table styles */
.nested-table {
  background-color: #f8f9fa; /* Use a slightly different background color */
  border: 1px solid #ced4da; /* Apply a subtle border */
  border-radius: 5px; /* Add border radius for rounded corners */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
}

/* Styling for nested table headers */
.nested-table th {
  background-color: #e9ecef; /* Use a different background color for headers */
  font-weight: bold; /* Make headers bold */
  padding: 8px 12px; /* Adjust padding */
  text-align: center; /* Center align text */
}

.glb-error-message {
  background-color: #f2545b; /* Candy Apple Red */
  color: #ffffff; /* Olaf White */
  text-align: center;
  padding: 15px 0;
  width: 100%;
  top: 0;
  left: 0;
  font-weight: 600; /* Medium to bold for visibility */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adds depth */
  font-family: 'Arial', sans-serif; /* Example font, adjust as needed */
}

.stockSheetDashboard {
  .component-card {
    margin-bottom: 0; // Remove bottom margin from cards to reduce space between tables
    &:not(:last-child) {
      border-bottom: none; // Remove the bottom border of all but the last card
    }
  }

  table {
    margin-bottom: 0; // Remove bottom margin from tables

    // Ensure consistent column widths across all tables
    // Example for setting specific widths; adjust as necessary for your columns
    th, td {
      &:nth-child(1) { width: 20%; } // Adjust the index for each column
      &:nth-child(2) { width: 20%; }
      padding-top: none;
      padding-bottom: none;
      // Add more as per your column count
    }

    td {
      padding-top: 1px;
      padding-bottom: 1px;
    }
  }

  // Hide headers for all but the first table
  .component-card:not(:first-child) {
    // thead {
    //   display: none;
    // }
  }
}


/* Responsive adjustments */
@media (max-width: 1100px) {
  .config-container .config-items {
    flex-direction: column;
  }

  .widget-width,
  .thin-widget-width {
    width: fit-content;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .project-widgets {
    flex-direction: column;
  }

  .right-column {
    width: 100%;
  }
}