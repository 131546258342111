.productsTotal {
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: space-between;
  align-items: center;

  &_label {
    margin-right: 1rem;
  }
  &_value {
    min-width: 8rem;
    color: $primary-color-blue;
    font-weight: bold;
    font-size: 1.8rem;

    margin-right: 3rem;
  }
}
