.auth {
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: white;
	width: 50%;
	height: fit-content;
	padding: 1% 5%;
	border-radius: 10px;
}

.auth-verifyEmail {
	align-items: center;

	& h1 {
		line-height: 100%;
		margin-bottom: 0;
	}

	& p {
		margin-bottom: 15px;
	}

	& .btn-submit {
		margin: 0 auto;
	}
}

.auth-login {
	& label {
		position: absolute;
		pointer-events: none;
		transform: translate(0, 15px) scale(1);
		transform-origin: top left;
		transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
		color: #6f81a5;
		font-size: 16px;
		line-height: 1;
		left: 50px;
	}

	& .rememberMe {
		position: relative !important;
		width: 50%;
		margin: -15px auto;
		height: 50px;

		& label {
			left: 25px; 
		}
	}

	& input {
		height: 45px;
		border-radius: 4px;
		border: none;
		padding: 17px 6px 0px 50px;
		font-size: 16px;
		line-height: 1;
		outline: none;
		box-shadow: none;
		transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
	}

	&-logout {

		&:hover {
			text-decoration: underline;
			cursor: pointer;
		}
	}

	& form {
		display: flex;
		flex-direction: column;
	}
}

.login {
	&_logo {
		display: flex;
		justify-content: center;

		& img {
			height: 25vh;
		}
	}

	&_details {
		height: 80%;
	}

	&_eye {
		position: absolute;
		margin: 10px 10px 0px 0px !important;
		height: 30px !important;
		right: 0;
	}


	&_singular {
		position: relative;
		display: flex;
		flex-direction: column;
		border: 1px solid;
		border-radius: 5px;
		margin-bottom: 15px;

		&:focus-within label {
			transform: translate(0, 5px) scale(0.8);
			color: #0a53e4;
		}

		& .filled {
			transform: translate(0, 5px) scale(0.8);
		}

		& svg {
			height: 20px;
			position: absolute;
			margin: 12px 8px;
		}
	}

	&_btn {
		color: white;
		height: 45px;
		font-size: 20px;
		font-weight: 700;
		width: 50%;
		border-radius: 10px;
		margin: 10px auto;
		-moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
		-webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
	}

	
}



