.sendEmail_attachments{
  & h3 {
    margin-bottom: 1.5rem;
  }

  & .sendEmail_list {
    margin-left: 2rem;
    font-size: 1.8rem;
    // line-height: 2rem;

    & li {
      margin-bottom: 1.4rem;

      &:first-of-type {
        font-weight: bold;
      }
    }
  }
}