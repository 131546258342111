.centerText {
    text-align:center; 
}
#syncButtons {
    height: 5vh;
    margin: 3vh;
    display: flex;
    justify-content: space-around;
}
#syncButtonsContainer {
    display: flex;
    gap: 10px;
}
#syncSection {
    height: 37vh;
    margin-bottom: 2vh;
    margin-top: 5vh;
}
#syncSection .quotesTable {
    overflow-y: auto;
    height: 63vh;
}
.userHeader {
    display: flex;
    justify-content: space-between;
}
#editEmail {
    width: 75%;
    margin: 5vh auto;
}

#editEmail textarea {
    width: 100%;
}
#editEmail button {
    margin: 2vh auto;
}
.table_deleteBtn {
    padding-right: 10px;
    padding-left: 5px; 
    display: none; 
    color: red;
    font-weight: bold;
    cursor: pointer;
}

.userDeleteBtn {
  margin-right: 5px;
  width: 20px;
}

.users_row:hover .table_deleteBtn, .variant_row:hover .table_deleteBtn, .manufacturer_row:hover .table_deleteBtn {
    display: inline-block;
}

form#addUser {
    height: 30vh;
    width: 50%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
#addUser div div input{
    width: 100%; 
}
#addUser div {
    width: 100%; 
}
#addVariant, #addManufacturer {
    margin: 10vh 0;
}
div#productVariantsScroll {
    display: table-header-group;
    /* width: 100%; */
}
#productVariantsScroll tr td {
    display: table-cell; 
}
#addVariant div, #addManufacturer div {
    text-align: center; 
}
#addVariant div input, #addVariant div select, #addManufacturer div input{
    width: 70%; 
}
#addVariant div label, #addManufacturer div label {
    display: block;
    text-align: left;
    width: 70%;
    margin: auto;
}
.block {
    display: block;
}
.centerButton {
    display: flex;
    justify-content: center; 
    margin-top: 5vh; 
}
.editEmailTable {
    margin: 20px auto;
    width: 75%;
    text-align: left;
}
.productButtons {
    display: flex;
    gap: 15px;
}
.variant_options input {
    width: 100%; 
}
.manufacturer_vendor {
    white-space: nowrap;
}