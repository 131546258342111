// @import url('https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,700;1,400&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  line-height: 1.4rem;

  & p,
  label {
    line-height: 2.2rem;
  }

  & h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  & h2 {
    font-size: 2.8rem;
    margin-bottom: 1rem;
  }
  & h3 {
    font-size: 2.6rem;
    margin-bottom: 1rem;
  }
  & h4 {
    font-size: 2.2rem;
    margin-bottom: 1rem;
  }
  & h5 {
    font-size: 2rem;
  }

  & input, textarea {
    font-size: 1.2rem;
  }
}
