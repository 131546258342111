.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -20rem);
  border: 2px solid $blue_nav_color;
  border-radius: 10px;
  z-index: 100;
  padding: 3rem 5rem;

  background-color: $app-bg-color;
}

// Delete quote modal in quote tool
.modal_deleteQuote{
 &_buttonContainer{
   display: flex;
   margin-top: 2rem;
   gap: 5rem;
   justify-content: center
 }
}