.deal_name {
  &_btn {
    display: inline-block;
  }

  & input {
    min-width: 215px;
  }

  // "or" p for splitting up deal line
  &_or{
    display: inline-block;
  }

  & error {
      color: red; 
  }
}