.quoteSummary {
    height: 100%;
    &_grid {
        display: grid;
        grid-template-columns: minmax(12rem, auto) minmax(10rem, auto);
    }

    &_label {
        &.totalPrice {
            font-weight: bold;
        }
    }

    &.internalNotes {
        margin-top: 1rem;
    }

    & textarea {
        width: 100%;
        height:90px;
        grid-column: span 2;
    }

    & input:disabled,
    & textarea:disabled,
    & select:disabled {
        color: rgb(103, 103, 103);
    }
}
