input[type='text']:disabled,
input[type='email']:disabled,
input[type='phone']:disabled,
input[type='number']:disabled,
input[type='select']:disabled,
select:disabled,
textarea:disabled {
    background: #f0f0f0;
}

.disabled {
    color: $color-disabled;
}

.active_btn {
    background-color: $primary-color-green;
    border: 1px solid $primary-color-green;
}

.disabled_btn {
    background-color: $color-disabled;
    border: 1px solid $primary-color-green;
}
