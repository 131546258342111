.installation {
  display: flex;
  flex-direction: column;

  &_grid {
    display: grid;
    grid-template-columns: minmax(15rem, auto) minmax(auto, 10rem);
    gap: 0 1rem;
  }

  #includeInstallation {
    align-self: center;
  }

  // Installation charge figures from database
  &_calculations {
    display: flex;
    gap: 2rem;
    font-size: 1.6rem;
    grid-column: span 2;
  }

  & label {
    line-height: 2.8rem;
  }
  // text input for additional discount

  & textarea {
    width: 100%;
    height: 325px;
    grid-column: span 2;
  }

  & input:disabled,
  & textarea:disabled,
  & select:disabled {
    color: rgb(103, 103, 103);
  }

  &_label {
    // "installation description" label
    &:last-of-type {
      margin-top: 1rem;
      grid-column: span 2;
    }
  }
}
