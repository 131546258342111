.btn {
  padding: .5rem 1rem;
  display: flex;
  align-items: center;
  background-color: $app-bg-color;
  border: 3px solid $color-grey-dark-5;
  border-radius: 5px;
  font-size: 1.2rem;
  text-decoration: none;
}

.btn:hover {
  cursor: pointer;
}

.btn-green {
  border-color: $primary-color-green;
}

.btn-disabled {
  color: $color-disabled;
  border-color: $color-disabled;

  &:hover {
    cursor: default;
  }
}

.btn-red {
  border-color: $primary-color-red;

  &:hover {
    color: $primary-color-red;
  }
}

.btn-blue {
  background-color: #47b9ff;

  &:hover {
    background-color: #3497D4;
  }
}


.btn-light-green {
  background-color: #70d370;

  &:hover {
    background-color: $color-primary-green;
  }
}

.btn-pm-orange {
  background-color: #f3781a;
  color: white;
  border: none;
  font-weight: bolder;
  padding: 13px 25px;
}

.btn-pm {
  border: none;
  font-weight: bolder;
  padding: 13px 25px;
}

.btn-small{
  display: inline-block;
  border: 1px solid $color-grey-dark-5;
}

.btn-issue{
  border-color: #f3781a;
  color: #f3781a;
  font-size: small;
  font-weight: bold;  
  background-color: white;
}

.btn-update-issue { 
  border-color: #3497D4;
  color: #3497D4;
  white-space: nowrap; 
  background-color: white;
}

.btn-delete {
  border-color: #BF0000;
  color: #BF0000;
  background-color: white;
}