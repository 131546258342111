.hubspotContact {
    & h4 {
        margin-bottom: 2rem;
    }
}

.hubspotContact_select {
    display: flex;
    height: 100%;
    margin-top: 5rem;
    margin-bottom: 3rem;

    & h3 {
        margin-right: 3rem;
    }

    & select {
        width: 30rem;
    }
}

// Form and Grid
.hubspotContact_details {
    display: grid;
    grid-template-columns: [labels] 1fr [controls] 3fr;
    grid-gap: 1rem;

    & > label {
        grid-column: labels;
    }

    & > input {
        grid-column: controls;
        border: 1px solid $color-grey-dark-3;
        border-radius: 2px;
    }

    // Require field span
    &_required {
        color: red;
    }

    &-general {
    }

    &-shipping {
    }

    // Same shipping as billing address check box div
    &_sameBillingAddress {
        margin-bottom: 1rem;
        & label {
            margin-right: 2rem;
        }
    }

    &-billing {
        margin-top: 5rem;
    }
    &-preparedBy {
    }
}
