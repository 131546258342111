th {
  font-weight: 400;
  border-bottom: 2px solid $color-grey-dark-3;
  padding: 0;
  // padding-bottom: 5px;
}

td {
  padding: 3px 1px;
}
