#root {
  width: 100%;

  // min-height: 100vh;
  // height: 100vh;
  // background-color: steelblue;
  // padding: 5rem;
}
.app {
    min-height: 100vh;
}

.app_container {
    height: 100%;
    width: 100%;
    padding: 2rem 5rem;
}
