//Center productsModal on Screen
#productsModal {
    border-radius: 5px; 
    width: 85%;
    height: 80%;
    background-color: #f5f8fa;
    position: absolute;
    left: 50%;
    top: 50%;
    border: 2px solid #00256a;
    padding: 0 5rem;
    min-height: 3.5rem;
    line-height: 3.5rem;
    transform: translate(-50%, -50%);
    position: fixed;
}
.headBorder {
    font-weight: 400;
    border-bottom: 2px solid #425b76;
    border-top: 2px solid #425b76;
    padding: 1rem;
}
.freightGroup .quotesTable table, .blueBorder {
    border: 2px solid #425b76;
    border-bottom: 2px solid #425b76 !important;
}
#productsModal.quotesTable {
    height: 90%;
    overflow-y: scroll;
}
span.close {
    position: absolute;
    right: 15px;
    top: 5px;
}
span.close:hover {
    cursor: pointer; 
    color: dimgrey; 
}
div#productContainer {
    height: 70%;
    margin: 0% 0% 1% 0%;
    overflow-y: scroll;
}
#productContainer .btn-submit {
    text-align: center;
    justify-content: center;
    width: 25%;
    margin: 10px auto;
}
#productContainer textarea {
    width: 100% !important;
    resize: none;
    height: 70px !important;
}
.inputWidth {
    width: 100%;
    border: 1px solid black;
}
.paletteInputWidth {
    width: 50%;
    border: 1px solid black;
}
.delete button{
    border-color: indianred;
    display: table-cell; 
}
td.delete {
    text-align: center;
}
div#productTitle {
    display: inline-flex;
    margin-top: 30px;
    width: 100%;
}
#productTitle h3 {
    margin-left: 10px;
    margin-right: 25px;
}
button.btn.updateProduct {
    justify-content: center;
    margin: 0 auto;
    width: 20%;
}
div#productsHeader {
    display: flex;
    justify-content: space-between;
}
.error {
    color: red; 
}