.loading_inline {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    &_title {
        color: black; 
        font-weight: normal;
    }

    & img {
        width: 2rem
    }

    & img.small-icon {
        height: 25px;
    }

    & img.large-icon {
        height: 100px;
        width: 100px;
    }
}
