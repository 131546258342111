nav {
  
  padding: 0 5rem;
  min-height: 3.5rem;
  line-height: 3.5rem;
}

nav ul {
  background-color: $app-bg-color;

  // Links styling
  list-style: none;
  display: flex;

  & li {
    font-weight: bold;
  }
  & img {
    height: 40px;
  }
}


// Back 'view quotes' link item
nav .nav_back {
  min-width: 15rem;
}

// Dynamic links if quote exits
// Nav items to display when there is a quote

// nav {
//   & .nav_quote_items {
//     display: grid;
//     grid-template-columns: auto auto;
//     width: 100%;
//     justify-content: space-between;
//   }

//   & .nav_quote_leftItems {
//     display: flex;
//     align-items: center;
//     gap: 5rem;
//   }

//   & .nav_quote_rightItems {
//     display: flex;
//     align-items: center;
//     width: 100%;
//     gap: 5rem;
//     justify-self: end;

//     // Quote number or 'new quote' display
//     & > p {
//       font-weight: bold;
//     }
//   }

// }


// Auth navbar
nav.nav_auth{
  & ul {
    display: flex;
    justify-content: center;
  }
}
