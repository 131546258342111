.quote_contactDetails {
    &_grid {
        display: grid;
        grid-template-columns: auto auto auto auto;
    }

    // First Grid Item
    h1 {
    }

    // Span in h1
    &_quoteNumber {
        font-size: 2.4rem;
    }

    &_primaryTag {
        color: red;
        margin-left: 1rem;
        font-size: 2rem;
    }
    &_quoteWonTag {
        color: green;
        margin-left: 1rem;
        font-size: 2rem;
        font-weight: bold;
    }

    &_createdOn {
    }

    &_grid-created {
        & label {
            margin-right: 2rem;
        }
        & input {
            max-width: 20rem;
        }
    }

    // Second Grid Item - UL
    &_contactInfo {
        list-style: none;

        & li {
            display: flex;

            & > p:first-child {
                min-width: 6rem;
            }
        }
    }

    // Third grid item
    &_shipping {
        display: flex;

        & p:first-child {
            margin-right: 1rem;
        }
    }

    &_grid-buttons {
        display: grid;
        text-align: center;
        align-items: flex-start;
        justify-items: center;

        & button {
            padding: 0.2rem 0.5rem;
            min-width: 13rem;
            margin: 0, auto;
            display: inline-block;
        }
    }
}
