.freight {
  &_grid {
    display: grid;
    grid-template-columns: minmax(15rem, auto) minmax(auto, 10rem);
  }

  &_calculations {
    display: flex;
    gap: 1.5rem;
  }

  // text input for additional discount
  & input {
    width: 100%;
  }

  & textarea {
    width: 100%;
    grid-column: span 2;
  }
}
