.contactDetails {
    &_grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10rem 10rem;

        & .hubspotContact_details input:not([type='checkbox']) {
            width: 90%;
        }
    }

    &_btn {
        padding: 5rem 0;
        display: flex;
        gap: 2rem;
    }
}
