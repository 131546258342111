.debug {
}

.debug_toggle {
  & > label {
    margin-right: 1rem;
  }

  & input {
  }
}
