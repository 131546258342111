.navbar_admin {
  padding: 0 5rem;
  padding-top: 1rem;
  // min-height: 2rem;
  // line-height: 3.5rem;
}

.navbar_admin ul {
  display: flex;
  list-style: none;
}