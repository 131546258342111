.freightHeader {
    display: flex;
    justify-content: space-between;
}
.freightHeader div {
    display: flex;
}
.freightHeader div button {
    margin-left: 10px;
}
#markup_label {
    font-size: 2rem;
    margin-right: 10px;
}
//Center Table Submit Button
.freightGroup button {
    /* justify-content: flex-end; */
    width: 20%;
    justify-content: center;
    margin: 1% auto;
}
.freightGroup .quotesTable {
    margin: 5%;
}
.freightGroup .quotesTable table, .blueBorder {
    border: 2px solid #425b76;
    border-bottom: 2px solid #425b76 !important;
}
.freightGroup td input {
    width: 85%;
    margin: 0% 15% 0% 0%;
}
.freightGroup .quotesTable th {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
tr.equation {
    border-bottom: 2px solid #425b76;
    background-color: #e2f0ff;
}
.addressInput {
    width: 100% !important;
    margin: 0 !important;
}