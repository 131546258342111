// Padding
.pad-xxs {
    padding: 3px;
}

.pad-xs {
    padding: 5px;
}

.pad-sm {
    padding: 8px;
}

.pad-md {
    padding: 12px;
}

.pad-lg {
    padding: 16px;
}

.pad-xlg {
    padding: 20px;
}

.pad-none {
    padding: 0px;
}

// Left Padding
.pad-l-none {
    padding-left: 0px;
}

.pad-l-xs {
    padding-left: 5px;
}

.pad-l-sm {
    padding-left: 8px;
}

.pad-l-smd {
    padding-left: 10px;
}

.pad-l-md {
    padding-left: 12px;
}

.pad-l-lg {
    padding-left: 16px;
}

.pad-l-xlg {
    padding-left: 20px;
}

.pad-l-xxlg {
    padding-left: 25px;
}

.pad-l-xxxlg {
    padding-left: 30px;
}

.pad-l-60 {
    padding-left: 60px
}

// Right Padding
.pad-r-none {
    padding-right: 0px;
}

.pad-r-xs {
    padding-right: 5px;
}

.pad-r-sm {
    padding-right: 8px;
}

.pad-r-md {
    padding-right: 12px;
}

.pad-r-lg {
    padding-right: 16px;
}

.pad-r-xlg {
    padding-right: 20px;
}

.pad-r-xxlg {
    padding-right: 25px;
}

.pad-r-xxxlg {
    padding-right: 30px;
}

.pad-r-60 {
    padding-right: 60px
}

// Top Padding
.pad-t-none {
    padding-top: 0px;
}

.pad-t-xs {
    padding-top: 5px;
}

.pad-t-sm {
    padding-top: 8px;
}

.pad-t-md {
    padding-top: 12px;
}

.pad-t-lg {
    padding-top: 16px;
}

.pad-t-xlg {
    padding-top: 20px;
}

.pad-t-xxlg {
    padding-top: 25px;
}

.pad-t-xxxlg {
    padding-top:30px;
}

// Bottom Padding
.pad-b-xs {
    padding-bottom: 5px;
}

.pad-b-sm {
    padding-bottom: 8px;
}

.pad-b-md {
    padding-bottom: 12px;
}

.pad-b-lg {
    padding-bottom: 16px;
}

.pad-b-xlg {
    padding-bottom: 20px;
}

.pad-b-xxlg {
    padding-bottom: 25px;
}

.pad-b-xxxlg {
    padding-bottom: 30px;
}

// Padding Left Right
.pad-lr-xs {
    padding-left: 5px;
    padding-right: 5px
}

.pad-lr-sm {
    padding-left: 8px;
    padding-right: 8px
}

.pad-lr-md {
    padding-left: 12px;
    padding-right: 12px
}

.pad-lr-lg {
    padding-left: 16px;
    padding-right: 16px
}

.pad-lr-xlg {
    padding-left: 20px;
    padding-right: 20px
}

.pad-lr-xxlg {
    padding-left: 25px;
    padding-right: 25px
}

.pad-lr-xxxlg {
    padding-left: 30px;
    padding-right: 30px
}

// Padding Top Bottom
.pad-tb-xs {
    padding-top: 5px;
    padding-bottom: 5px
}

.pad-tb-sm {
    padding-top: 8px;
    padding-bottom: 8px
}

.pad-tb-md {
    padding-top: 12px;
    padding-bottom: 12px
}

.pad-tb-lg {
    padding-top: 16px;
    padding-bottom: 16px
}

.pad-tb-xlg {
    padding-top: 20px;
    padding-bottom: 20px
}

.pad-tb-xxlg {
    padding-top: 25px;
    padding-bottom: 25px
}

.pad-tb-xxxlg {
    padding-top: 30px;
    padding-bottom: 30px
}

// Left Margin
.margin-l-xs {
    margin-left: 5px;
}

.margin-l-sm {
    margin-left: 8px;
}

.margin-l-md {
    margin-left: 12px;
}

.margin-l-lg {
    margin-left: 16px;
}

.margin-l-xlg {
    margin-left: 20px;
}

.margin-l-xxlg {
    margin-left: 25px;
}

.margin-l-xxxlg {
    margin-left: 30px;
}

.margin-l-auto {
    margin-left: auto;
}

// Right Margin
.margin-r-xs {
    margin-right: 5px;
}

.margin-r-sm {
    margin-right: 8px;
}

.margin-r-md {
    margin-right: 12px;
}

.margin-r-lg {
    margin-right: 16px;
}

.margin-r-xlg {
    margin-right: 20px;
}

.margin-r-xxlg {
    margin-right: 25px;
}

.margin-r-xxxlg {
    margin-right: 30px;
}

.margin-r-48px {
    margin-right: 48px;
}

// Top Margin
.margin-t-xxs {
    margin-top: 2px;
}

.margin-t-xs {
    margin-top: 5px;
}

.margin-t-sm {
    margin-top: 8px;
}

.margin-t-md {
    margin-top: 12px;
}

.margin-t-lg {
    margin-top: 16px;
}

.margin-t-xlg {
    margin-top: 20px;
}

.margin-t-xxlg {
    margin-top: 25px;
}

// Bottom Margin
.margin-b-none {
    margin-bottom: 0px;
}

.margin-b-xxs {
    margin-bottom: 4px;
}

.margin-b-xs {
    margin-bottom: 5px;
}

.margin-b-sm {
    margin-bottom: 8px;
}

.margin-b-md {
    margin-bottom: 12px;
}

.margin-b-lg {
    margin-bottom: 16px;
}

.margin-b-xlg {
    margin-bottom: 20px;
}

.margin-b-xxlg {
    margin-bottom: 25px;
}

// Margin All
.margin-xs {
    margin: 5px;
}

.margin-sm {
    margin: 8px;
}

.margin-md {
    margin: 12px;
}

.margin-lg {
    margin: 16px;
}

.margin-xlg {
    margin: 20px;
}

.margin-xxlg {
    margin: 25px;
}

.margin-xxxlg {
    margin: 30px;
}

// Margin Left Right
.margin-lr-xxs {
    margin-left: 2px;
    margin-right: 2px
}

.margin-lr-xs {
    margin-left: 5px;
    margin-right: 5px
}

.margin-lr-sm {
    margin-left: 8px;
    margin-right: 8px
}

.margin-lr-md {
    margin-left: 12px;
    margin-right: 12px
}

.margin-lr-lg {
    margin-left: 16px;
    margin-right: 16px
}

.margin-lr-xlg {
    margin-left: 20px;
    margin-right: 20px
}

.margin-lr-xxlg {
    margin-left: 25px;
    margin-right: 25px
}

.margin-lr-xxxlg {
    margin-left: 30px;
    margin-right: 30px
}

// Margin Top Bottom
.margin-tb-xxs {
    margin-top: 2px;
    margin-bottom: 2px
}

.margin-tb-xs {
    margin-top: 5px;
    margin-bottom: 5px
}

.margin-tb-sm {
    margin-top: 8px;
    margin-bottom: 8px
}

.margin-tb-md {
    margin-top: 12px;
    margin-bottom: 12px
}

.margin-tb-lg {
    margin-top: 16px;
    margin-bottom: 16px
}

.margin-tb-xlg {
    margin-top: 20px;
    margin-bottom: 20px
}

.margin-tb-xxlg {
    margin-top: 25px;
    margin-bottom: 25px
}

.margin-tb-xxxlg {
    margin-top: 30px;
    margin-bottom: 30px
}

// Margin Top Bottom Negative
.margin-tb-xxs-negative {
    margin-top: -2px;
    margin-bottom: -2px
}

.margin-none {
    margin: 0px;
}

// gap options for flexbox
.gap-xsm{
  padding-left: 10px;
}

.gap-sm{
  gap: 15px;
}

.gap-md{
  gap: 20px;
}

.gap-lg{
  gap: 25px;
}

.gap-xlg{
  gap: 30px;
}

.gap-xxlg{
  gap: 35px;
}

.row-gap-sm {
    row-gap: 5px;
}

.row-gap-md {
    row-gap: 12px;
}

.column-gap-sm, .col-gap-sm {
    column-gap: 5px;
}

.column-gap-md {
    column-gap: 12px;
}

.column-gap-lg {
    column-gap:20px
}

//flex 
.flex-1 {
  flex: 1;
}

.flex-2{
  flex: 2;
}

.flex-3{
  flex: 3;
}