* {
    font-family: sans-serif;
}

.mainContainer {
    width: 100%;
}

.headerDiv {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.header {
    font-size: 24pt;
    justify-self: flex-start;
    font-weight: bold;
    margin-bottom: 15px;
}

.buttonContainer {
    display: flex;
    justify-content: space-evenly;
    justify-self: flex-end;
    gap: 15px;
}

.discount_box {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
}

.quoteNumber {
    margin-top: 15px;
    font-size: 16pt;
    font-weight: bold;
}

.infoBox_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 5px;
    height: 350px;
    @media (min-width: 112.5em) {
        height: 400px;
    }
}

.contactInfo {
    margin-top: 15px;
    font-size: 10pt;
    display: flex;
    gap: 100px;
}

.mainBox {
    box-shadow: 0px 2px 2px rgb(207, 198, 198);
}

.install_discount_column {
    flex: 1;
    height: 100%;
}

.installation_info {
    padding: 10px;
}

.info_input {
    width: 65px;
}

.description_input {
    width: 85%;
    margin-left: 5px;
}

.discount_field {
    display: flex;
    margin-top: 2px;
}

.infoBox_header {
    font-size: 20pt;
    font-weight: bold;
}

.freight_column_main {
    flex: 1;
    height: 100%;
}

.freight_column {
    display: flex;
    gap: 20px;
    padding: 10px;
}

.summary_Row {
    display: flex;
    gap: 10px;
    flex: 1;
    text-align: center;
}

.summary_column {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 2;
    height: 100%;
}

.summary_header {
    font-size: 14pt;
    font-weight: 900;
}

.summary_item {
    border: 2px solid rgb(67, 197, 67);
    padding: 25px;
}

.summary_total {
    font-size: 20pt;
    font-weight: 800;
    margin-top: 15px;
}

.large_item {
    flex: 2.5;
}

.small_item {
    flex: 1;
}

.info_highlight {
    font-weight: bold;
}

.cost_table {
    width: 100%;
    margin-top: 15px;
}

.cost_table_head {
    border-bottom: 2px solid orangered;
    display: flex;
}

.quote_items {
    width: 100%;
    margin-top: 30px;
}

.table_header {
    font-weight: bold;
    font-size: 12px;
    flex: 1;
    border: none;
}

.product_cell {
    font-size: 14px;
    flex: 1;
    text-align: center;
}

.product_row {
    margin-top: 10px;
    width: 100%;
    display: flex;
}
