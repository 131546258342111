.discount {
  display: flex;
  flex-direction: column;
  height: 100%;
  &_grid {
    display: grid;
    grid-template-columns: minmax(12rem, auto) minmax(auto, 10rem);
  }

  // text input for additional discount
  & input {
    width: 100%;
  }

  & textarea {
    width: 100%;
    grid-column: span 2;
    height: 80px;
  }
}
