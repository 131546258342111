div.quote_view {
  display: flex;
  flex-direction: column;
}

div.quote_view-topHalf {
  display:flex;
}
// First row
section.quote_view-leftSection {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
}

// Second row
section.quote_view-rightSection {
  margin-bottom: 3rem;
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 1rem;

  // Quote summary and Discount Grid
  & .quote_view-secondColumn {
    display: flex;
    flex-direction: column;
    row-gap: 9px;
    width: 300px;
  }

  & .quote_view-summary {
    display: flex;
    flex-direction: column;
    row-gap: 9px;
  }
}
