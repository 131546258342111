// colors

$primary-color-blue: rgb(0, 123, 255);
$primary-color-red: rgb(216, 0, 0);
$primary-color-green: rgb(0, 108, 2);
// Hubspot colors:
$color-primary-calypso: #00a4bd;
$color-primary-lorax: #ff7a59;

//Playground Boss Theme colors:
$color-primary-orange: #C6793D;
$color-primary-green: #2AAE49; 

$color-secondary-oz: #00bda5;
$color-secondary-thunderdome: #6a78d1;
$color-secondary-candyApple: #f2545b;
$color-secondary-norman: #f2547d;
$color-secondary-sorbet: #ff8f59;
$color-secondary-marigold: #f5c26b;

// Eerie
$color-grey-dark-1: #7c98b6;
// Slinky
$color-grey-dark-2: #516f90;
// Heffalump
$color-grey-dark-3: #425b76;
// Obsidian
$color-grey-dark-4: #33475b;
// Pantera
$color-grey-dark-5: #2d3e50;
// Flat Earth
$color-grey-dark-6: #253342;

// Olaf
$color-grey-light-1: #ffffff;
// Gypsum
$color-grey-light-2: #f5f8fa;
// Koala
$color-grey-light-3: #eaf0f6;
// Great White
$color-grey-light-4: #dfe3eb;
// Battleship
$color-grey-light-5: #cbd6e2;
// Flint
$color-grey-light-6: #99acc2;

// Layout Background color
$app-bg-color: $color-grey-light-2;

// Nav color
$blue_nav_color: rgb(0, 37, 106);

// Disabled color
$color-disabled: rgb(135, 135, 135);


